import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
    Box, Card, CardContent,
    TextField, Button, FormControl,
    Grid, TextareaAutosize, Select, MenuItem, InputLabel, FormHelperText,
} from '@material-ui/core';
import { yearList } from '@utils/constent'
import Loader from '@components/Loader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { useNavigate } from 'react-router-dom';
import { getImageUrl, postApiReq, getApiReq } from 'src/utils/ApiHandlers';
import { createMovieValidation } from '@utils/validation';
import { isYupError, parseYupError } from "@utils/Yup";

const initialState = {
    movieName: '',
    description: '',
    logo: '',
    coverImage: '',
    year: 2023,
    agencyId: '',
}

export default function CreateAndUpdateMovie() {
    const [form, setForm] = useState(initialState)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [agencyList, setAgencyList] = useState([])

    const uploadFile = async (e, index, type, assetType) => {
        let data = new FormData();
        data.append('asset', e.target.files[0])
        let image = await getImageUrl(data)
        if (index === 0 || index) {
            let temp = form[assetType].slice(0);
            temp[index]['asset'][type] = image

            setForm({
                ...form, [assetType]: temp
            })
            if (errors[assetType]) {
                let tempError = errors[assetType].slice(0)

                tempError[index]['asset'][type] = ''
                setErrors({
                    ...errors, [assetType]: tempError
                })
            }


        }
        else {
            setForm({
                ...form, [e.target.name]: image
            })
            setErrors({
                ...errors, [e.target.name]: ''
            })
        }
    }

    const getAgencyList = () => {
        setIsLoading(true);
        getApiReq(`/admin/get-year-agency?year=${form.year}`)
          .then(res => {
            if (res) {
              setAgencyList(res.data)
              setIsLoading(false);
            }
            else {
              setIsLoading(false);
            }
          }).catch(e => { console.log(e) })
      };
      useEffect(() => {
        getAgencyList()
      }, [form.year])
    

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            setErrors({});
            await createMovieValidation.validate(form, {
                abortEarly: false,
            });
            postApiReq(`/admin/create-movie`, form)
                .then(res => {
                    if (res.status) {
                        dispatch(showToast({ message: 'Movie created successfully', type: 'success' }))
                        navigate('/movies')
                        setIsLoading(false);
                    }
                    else {
                        dispatch(showToast({ message: res.error, type: 'error' }))
                        setIsLoading(false);
                    }
                }).catch(e => { console.log(e) })
        } catch (error) {
            if (isYupError(error)) {
                setErrors(parseYupError(error));
            } else {

            }
            setIsLoading(false)
        }
    };

    const handleChange = (e) => {
        let { name, value } = e.target
        setForm({
            ...form, [name]: value
        })
        setErrors({
            ...errors, [name]: ''
        })
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Typography className={styles.heading}>Create Movie</Typography>
                <p className={styles.paragraph}>A series of games or contests that make up a single unit of competition.</p>
                <Box mt={3}>
                    <Card>
                        <CardContent >
                            <Box ml={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={10} >
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            error={errors.movieName}
                                            helperText={errors.movieName}
                                            type="text"
                                            label="Movie name"
                                            name="movieName"
                                            value={form.movieName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid container spacing={2} ml={1}>
                                        <Grid item xs={12} lg={5}>
                                            <FormControl fullWidth error={errors.year}>
                                                <InputLabel id="demo-simple-select-label">Agency year</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={form.year}
                                                    name="year"
                                                    label="Agency year"
                                                    onChange={handleChange}
                                                >
                                                    {yearList.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                                                </Select>
                                                <FormHelperText>{errors.year}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={5}>
                                            <FormControl fullWidth error={errors.agencyId}>
                                                <InputLabel id="demo-simple-select-label">Select Agency</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={form.agencyId}
                                                    label="Select Agency"
                                                    name="agencyId"
                                                    onChange={handleChange}
                                                >
                                                    {agencyList.map((item, index) => <MenuItem key={index} value={item._id}>{item.agencyName}</MenuItem>)}
                                                </Select>
                                                <FormHelperText>{errors.agencyId}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid className={styles.imagesContainer} item xs={12} lg={5}>
                                            <Button
                                                className={styles.uploadButton}
                                                variant="outlined"
                                                component="label"
                                            >
                                                Upload Logo Image
                                                <input
                                                    type="file"
                                                    name="logo"
                                                    onChange={uploadFile}
                                                    hidden
                                                />
                                            </Button>
                                            {form.logo && <img className={styles.image} src={form.logo} alt='logo' />}
                                            {errors.logo && <small className='errormsg'>{errors.logo}</small>}
                                        </Grid>

                                        <Grid className={styles.imagesContainer} item xs={12} lg={5}>
                                            <Button
                                                className={styles.uploadButton}
                                                variant="outlined"
                                                component="label"
                                            >
                                                Upload Cover Image
                                                <input
                                                    type="file"
                                                    name="coverImage"
                                                    onChange={uploadFile}
                                                    hidden
                                                />
                                            </Button>
                                            {form.coverImage && <img className={styles.image} src={form.coverImage} alt='cover' />}
                                            {errors.coverImage && <small className='errormsg'>{errors.coverImage}</small>}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <TextareaAutosize
                                            aria-label="description"
                                            minRows={7}
                                            placeholder="Description"
                                            name="description"
                                            onChange={handleChange}
                                            style={{ width: '100%' }}
                                        />
                                        {errors.description && <small className='errormsg'>{errors.description}</small>}
                                    </Grid>
                                    <hr />

                                    <Grid item xs={11} align="right">
                                        <Button size="large" variant="contained" onClick={handleSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Loader isLoading={isLoading} />
            </TableContainer >
        </React.Fragment >
    );
}
