import React, { useEffect, useState } from "react";
import { Typography, Divider } from "@mui/material";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  TextareaAutosize,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { IconButton } from '@mui/material';
import { Add as AddIcon, Check as CheckIcon } from '@mui/icons-material';
import { Select, MenuItem } from "@mui/material";
import Loader from "@components/Loader";
import { useParams } from "react-router-dom";
import { getImageUrl, postApiReq, getApiReq } from "src/utils/ApiHandlers";
import { createAflTeamValidation } from "@utils/validation";
import { isYupError, parseYupError } from "@utils/Yup";
import { imageHandler } from "src/utils/formatter";

const initialState = {
  teamName: "",
  logo: "",
  abbreviation: "",
  description: "",
  players: [],
  sportType: "",
};
const _sportType = {
  CRICKET: "Fantasy Cricket",
  SOCCER: "Fantasy Soccer",
};
export default function AddAflTeam({ isModal = false, handleClose }) {
  const { id } = useParams();
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [playerList, setPlayerList] = useState([]);

  const getPlayerList = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-afl-players?sportType=${form.sportType}`)
      .then((res) => {
        if (res) {
          setPlayerList(res.data);
          setIsLoading(false);
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTeamDetails = () => {
    getApiReq(`/admin/get-afl-team-details/${id}`)
      .then((res) => {
        if (res.status) {
          setForm(res.data);
          setIsLoading(false);
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (form.sportType) getPlayerList();
  }, [form.sportType]);
  useEffect(() => {
    if (id) {
      getTeamDetails();
    }
  }, [id]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const uploadFile = async (e) => {
    setIsLoading(true);
    let data = new FormData();
    data.append("asset", e.target.files[0]);
    let image = await getImageUrl(data);
    setForm({
      ...form,
      logo: image,
    });
    setErrors({
      ...errors,
      logo: "",
    });
    setIsLoading(false);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      setErrors({});
      await createAflTeamValidation.validate(form, {
        abortEarly: false,
      });
      if (id) {
        postApiReq(`/admin/update-afl-team/${id}`, form)
          .then((res) => {
            if (res.status) {
              dispatch(
                showToast({
                  message: "Team updated successfully",
                  type: "success",
                })
              );
              if (isModal) handleClose();
              else {
                navigate("/afl-team");
              }

              setIsLoading(false);
            } else {
              dispatch(showToast({ message: res.error, type: "error" }));
              setIsLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        postApiReq(`/admin/add-afl-team`, form)
          .then((res) => {
            if (res.status) {
              dispatch(
                showToast({
                  message: "Team created successfully",
                  type: "success",
                })
              );
              if (isModal) handleClose();
              else {
                navigate("/afl-team");
              }

              setIsLoading(false);
            } else {
              dispatch(showToast({ message: res.error, type: "error" }));
              setIsLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
      }
      setIsLoading(false);
    }
  };
  const handleToggleUser = (userId) => () => {
    const currentIndex = form.players.indexOf(userId);
    const newSelectedUsers = [...form.players];

    if (currentIndex === -1) {
      newSelectedUsers.push(userId);
    } else {
      newSelectedUsers.splice(currentIndex, 1);
    }

    setForm({ ...form, players: newSelectedUsers });
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>
          {id
            ? "Update Avatar Fantasy League Team"
            : "Avatar Fantasy League Team "}
        </Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5} xl={5}>
                  <TextField
                    margin="normal"
                    fullWidth
                    error={errors.teamName}
                    helperText={errors.teamName}
                    type="text"
                    label="Name"
                    name="teamName"
                    value={form.teamName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={5} xl={5}>
                  <TextField
                    margin="normal"
                    fullWidth
                    error={errors.abbreviation}
                    helperText={errors.abbreviation}
                    type="text"
                    label="Abbreviation"
                    name="abbreviation"
                    value={form.abbreviation}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  className={styles.imagesContainer}
                  item
                  xs={12}
                  lg={5}
                  xl={5}
                  mt={2}
                >
                  <Button
                    className={styles.uploadButton}
                    variant="outlined"
                    component="label"
                  >
                    Upload logo image
                    <input type="file" hidden onChange={uploadFile} />
                  </Button>
                  <Grid item xs={12} lg={5} xl={5} mt={2}>
                    {form.logo && (
                      <img
                        className={styles.image}
                        src={imageHandler(form.logo, 1)}
                        alt=""
                      />
                    )}
                    {errors.logo && (
                      <small className="errormsg">{errors.logo}</small>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={5} xl={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Sport Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="sportType"
                      label="Sport Type"
                      value={form.sportType}
                      input={<OutlinedInput label="Sport Type" />}
                      onChange={handleChange}
                    >
                      {Object.keys(_sportType).map((key, index) => (
                        <MenuItem key={index} value={key}>
                          {_sportType[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={5} xl={5}>
                  <TextareaAutosize
                    aria-label="Description"
                    minRows={7}
                    placeholder="Description"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                  {errors.description && (
                    <small className="errormsg">{errors.description}</small>
                  )}
                </Grid>

                <Grid item xs={12} lg={10} xl={10}>
                  <Typography variant="h5" gutterBottom>
                    {"Player List"}
                  </Typography>
                  <Divider />
                  <Grid container spacing={3} mt={5} >
      {playerList.map((item) => (
        <Grid item xs={12} sm={6} key={item._id}>
          <Card>
            <CardContent style={{ display: 'flex', alignItems: 'center' , 
                backgroundColor: '#fff',
                boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.5)',
                borderRadius: '8px',
                cursor: 'pointer'
            }}
            onClick={handleToggleUser(item._id)}
            >
              <img
                className={styles.roundImg}
                src={imageHandler(item.avatar, 4)}
                alt={item.playerName}
                style={{ marginRight: '16px' }}
              />
              <div style={{ flex: 1 }}>
                <Typography variant="h6">{item.playerName}</Typography>
                <Typography variant="p">{(item.type).replace('_',' ')}</Typography>
              </div>
              <IconButton>
                {form.players.includes(item._id) ? <CheckIcon  /> : <AddIcon />}
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
                  
                </Grid>
                <Grid item xs={11} align="right">
                  <Button
                    size="large"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
