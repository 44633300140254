import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
  Box, Card, CardContent,
  TextField, InputAdornment, SvgIcon, Grid, Button, TableFooter, TablePagination
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import Loader from '@components/Loader';
import { useNavigate } from 'react-router-dom';
import { cutString } from 'src/utils/BasicRequest';
import { getApiReq } from 'src/utils/ApiHandlers';
import { imageHandler } from 'src/utils/formatter';


export default function AflAvatar() {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const [fanCardList, setFanCardList] = useState([])
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [count, setCount] = useState(0)
  const dispatch = useDispatch();


  const getTeamList = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-afl-avatar?search=${search}&rows=${rowsPerPage}&offset=${offset * 20}`)
      .then(res => {
        if (res.status) {
          setCount(res.data.count || 10)
          setFanCardList(res.data.rows)
          setIsLoading(false);
        }
        else {
          dispatch(showToast({ message: res.error, type: 'error' }))
          setIsLoading(false);
        }
      }).catch(e => { console.log(e) })
  };

  useEffect(() => {
    getTeamList();
  }, [search, offset]);


  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);

  };
  return (
    <React.Fragment>

      <Typography className={styles.heading}>Avatar For Player</Typography>
      <Box mt={3} component={Paper}>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  value={search}
                  onChange={(e) => { setSearch(e.target.value) }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} align="right">
                <Button onClick={() => navigate('/add-afl-avatar')} size="large" variant="contained" startIcon={<AddCircleIcon />}>Create Avatar Pack</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table className='table' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tabletitle}>S No.</TableCell>
              <TableCell className={styles.tabletitle} align="center">Image</TableCell>
              <TableCell className={styles.tabletitle} align="center">Name</TableCell>
              <TableCell className={styles.tabletitle} align="center">Type</TableCell>
              <TableCell className={styles.tabletitle} align="center">Supply</TableCell>
              <TableCell className={styles.tabletitle} align="center">Is Minted</TableCell>
  
            </TableRow>
          </TableHead>
          <TableBody>
            {fanCardList?.map((item, index) => (
              <TableRow
                key={index}
                className={styles.tableRow}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell  >{index + (offset * 10) + 1}</TableCell>
                <TableCell className={styles.dataView} align="center" ><img className={styles.roundImg} src={imageHandler(item.fancardImage, 10)} alt="" /></TableCell>
                <TableCell className={styles.dataView} align="center" >{item.title}</TableCell>
                <TableCell className={styles.dataView} align="center" >{item.cardType}</TableCell>
                <TableCell className={styles.dataView} align="center" >{item.supply}</TableCell>
                <TableCell className={styles.dataView} align="center" >{item.isMinted?'Yes':'No'}</TableCell>
              </TableRow>

            ))}

          </TableBody>



        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,]}
          colSpan={3}
          count={count}
          rowsPerPage={rowsPerPage}
          page={offset}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {fanCardList?.length < 1 &&
        <Box align={'center'} w='100%' p={5} >
          <Typography variant="body1" textAlign="center" color="textSecondary">Data not Available</Typography>
        </Box>}
      <Loader isLoading={isLoading} />
    </React.Fragment >
  );
}
