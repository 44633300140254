import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
    Box, Card, CardContent,Button,
    Grid
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Loader from '@components/Loader';
import { useNavigate } from 'react-router-dom';
import { getApiReq } from 'src/utils/ApiHandlers';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { imageHandler } from 'src/utils/formatter';
import moment from 'moment';

export default function MovieHeroes() {
    const { id ,movieName,agencyId} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const navigate = useNavigate();
    const [herosList, setHerosList] = useState([])
    const dispatch = useDispatch();

    const getTeamList = () => {
        setIsLoading(true);
        getApiReq(`/admin/movie-heroList/${id}`)
            .then(res => {
                if (res) {
                    setHerosList(res.data)
                    setIsLoading(false);
                }
                else {
                    dispatch(showToast({ message: res.error, type: 'error' }))
                    setIsLoading(false);
                }
            }).catch(e => { console.log(e) })
    };

    useEffect(() => {
        getTeamList();
    }, [search]);
    return (
        <React.Fragment>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Typography className={styles.heading}>Movie Heroes</Typography>
                <p className={styles.paragraph}>A series of games or contests that make up a single unit of competition.</p>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>                           
                                <Grid item xs={6} align="left">
                                    <Button variant="text" onClick={() => navigate(`/movies`)} size="large"  startIcon={<ArrowBackIcon />}>Back</Button>
                                </Grid>
                                <Grid item xs={6} align="right">
                                    <Button onClick={() => navigate(`/associate-hero/${id}/${movieName}/${agencyId}`)} size="large" variant="contained" startIcon={<AddCircleIcon />}>Add a Heros in Movie</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
                {/* <InfiniteScroll
          dataLength={length}
          next={getMoreList}
          hasMore={true}
          style={{ overflow: 'none' }}
        > */}

                <Table className='table' sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.tabletitle}> S No.</TableCell>
                            <TableCell className={styles.tabletitle} align="center">SuperHero Profile</TableCell>
                            <TableCell className={styles.tabletitle} align="center">SuperHero Name</TableCell>
                            <TableCell className={styles.tabletitle} align="center">Date</TableCell>

                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {herosList?.map((item, index) => (
                            <TableRow
                                className={styles.tableRow}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell  >{index + 1}</TableCell>
                                <TableCell className={styles.dataView} align="center" ><img className={styles.roundImg} src={imageHandler(item.profile, 6)} alt=''/></TableCell>
                                <TableCell className={styles.dataView} align="center" >{item.name}</TableCell>                                
                                <TableCell className={styles.dataView} align="center" >{moment(item.createdAt).format('DD-MM-YYYY')}</TableCell>   
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>

                {herosList?.length < 1 && <Box align={'center'} w='100%' p={5} >
                    <Typography variant="body1" textAlign="center" color="textSecondary">No any player associated to team</Typography>
                </Box>}

                {/* </InfiniteScroll> */}
                <Loader isLoading={isLoading} />
            </TableContainer >

        </React.Fragment >
    );
}
