import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  TextareaAutosize,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Select, MenuItem } from "@mui/material";
import Loader from "@components/Loader";

import { useParams } from "react-router-dom";
import { getApiReq, postApiReq } from "src/utils/ApiHandlers";
import { createAflMatchValidation } from "@utils/validation";
import { isYupError, parseYupError } from "@utils/Yup";
import moment from "moment";

const initialState = {
  matchName: "",
  description: "",
  firstTeam: "",
  secondTeam: "",
  place: "",
  cardType:'',
  startTime:moment().format("YYYY-MM-DDThh:mm"),
  endTime:moment().format("YYYY-MM-DDThh:mm"),
  sportType:''
};

const _sportType = {
  CRICKET: "Fantasy Cricket",
  SOCCER: "Fantasy Soccer",
};

export default function AddAflMatch({ isModal = false, handleClose }) {
  const { id } = useParams();
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [teamList, setTeamList] = useState([]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let data = {
      ...form,
      startTime: `${form.startTime}:00.000Z`,
      endTime: `${form.endTime}:00.000Z`
  }       

    try {
      setErrors({});
      await createAflMatchValidation.validate(data, {
        abortEarly: false,
      });
      postApiReq(`/admin/add-afl-match`, data)
        .then((res) => {
          if (res.status) {
            dispatch(
              showToast({
                message: "Match created successfully",
                type: "success",
              })
            );
            if (isModal) handleClose();
            else {
              navigate("/afl-match");
            }

            setIsLoading(false);
          } else {
            dispatch(showToast({ message: res.error, type: "error" }));
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
      }
      setIsLoading(false);
    }
  };
  const getTeamList = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-afl-team?sportType=${form.sportType}`)
      .then((res) => {
        if (res) {
          setTeamList(res.data);
          setIsLoading(false);
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (form.sportType) getTeamList();
  }, [form.sportType]);
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>Avatar Fantasy League Match</Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5} xl={5}>
                  <TextField
                    margin="normal"
                    fullWidth
                    error={errors.matchName}
                    helperText={errors.matchName}
                    type="text"
                    label="Name"
                    name="matchName"
                    value={form.matchName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={5} xl={5}>
                  <TextField
                    margin="normal"
                    fullWidth
                    error={errors.place}
                    helperText={errors.place}
                    type="text"
                    label="Place"
                    name="place"
                    value={form.place}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={5} xl={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Sport Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="sportType"
                      label="Sport Type"
                      value={form.sportType}
                      input={<OutlinedInput label="Sport Type" />}
                      onChange={handleChange}
                    >
                      {Object.keys(_sportType).map((key, index) => (
                        <MenuItem key={index} value={key}>
                          {_sportType[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <FormControl fullWidth error={errors.sportId}>
                    <InputLabel id="demo-simple-select-label">
                      Card Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="cardType"
                      label="Card Type"
                      value={form.cardType}
                      input={<OutlinedInput label="Card Type" />}
                      onChange={handleChange}
                    >
                              <MenuItem  value={'ALL'}>
                          All
                        </MenuItem>                      
                        <MenuItem  value={'BLUE'}>
                          Blue
                        </MenuItem>
                        <MenuItem  value={'SILVER'}>
                        Silver
                        </MenuItem>
                      
                        <MenuItem  value={'GOLD'}>
                        Gold
                        </MenuItem>
                      
                        <MenuItem  value={'BLACK'}>
                          Black
                        </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={5} mt={2}>
                  <FormControl fullWidth error={errors.sportId}>
                    <InputLabel id="demo-simple-select-label">
                      First Team
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="firstTeam"
                      label="First Team"
                      value={form.firstTeam}
                      input={<OutlinedInput label="First Team" />}
                      onChange={handleChange}
                    >
                      {teamList.map((item, index) => (
                        <MenuItem disabled={item._id===form.secondTeam} key={index} value={item._id}>
                          {item.abbreviation}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={5} mt={2}>
                  <FormControl fullWidth error={errors.sportId}>
                    <InputLabel id="demo-simple-select-label">
                      Second Team
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="secondTeam"
                      label="Second Team"
                      value={form.secondTeam}
                      input={<OutlinedInput label="Second Team" />}
                      onChange={handleChange}
                    >
                      {teamList.map((item, index) => (
                        <MenuItem disabled={item._id===form.firstTeam} key={index} value={item._id}>
                          {item.abbreviation}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <TextField
                    id="datetime-local"
                    label="Start Time"
                    type="datetime-local"
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="startTime"
                    value={form.startTime}
                    onChange={handleChange}
                    error={errors.startTime}
                    helperText={errors.startTime}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <TextField
                    id="datetime-local"
                    label="End Time"
                    type="datetime-local"
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="endTime"
                    value={form.endTime}
                    onChange={handleChange}
                    error={errors.endTime}
                    helperText={errors.endTime}
                  />
                </Grid>
               

                <Grid item xs={12} lg={5}>
                  <TextareaAutosize
                    aria-label="description"
                    minRows={7}
                    placeholder="Description"
                    name="description"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                  {errors.description && (
                    <small className="errormsg">{errors.description}</small>
                  )}
                </Grid>

                <Grid item xs={11} align="right">
                  <Button
                    size="large"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
