import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Select, MenuItem } from "@mui/material";
import Loader from "@components/Loader";
import { useParams } from "react-router-dom";
import { getImageUrl, postApiReq } from "src/utils/ApiHandlers";
import { createAflPlayerValidation } from "@utils/validation";
import { isYupError, parseYupError } from "@utils/Yup";

const playerType = {
  CRICKET: {
    ALL_ROUNDER: "Allrounder",
    BATSMEN: "Batsman",
    BOWLER: "Bowler",
    WICKET_KEEPER: "Wicketkeeper",
  },
  SOCCER: {
    GOAL_KEEPER: "Goalkeeper",
    DEFENDER: "Defender",
    MID_FIELDER: "Midfielder",
    FORWARD: "Forward",
  },
};
const _sportType = {
  CRICKET: "Fantasy Cricket",
  SOCCER: "Fantasy Soccer",
};
const initialState = [
  {
    playerName: "",
    avatar: "",
    type: "",
  },
  {
    playerName: "",
    avatar: "",
    type: "",
  },
  {
    playerName: "",
    avatar: "",
    type: "",
  },
  {
    playerName: "",
    avatar: "",
    type: "",
  },
  {
    playerName: "",
    avatar: "",
    type: "",
  },
];

export default function AddAflPlayer({ isModal = false, handleClose }) {
  const { id } = useParams();
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [sportType, setSportType] = useState("CRICKET");
  const dispatch = useDispatch();

  const handleChange = (e, index) => {
    let temp = form.slice(0);
    temp[index][e.target.name] = e.target.value;

    setForm(temp);

    if (errors) {
      let tempError = errors.slice(0);
      tempError[index][e.target.name] = "";
      setErrors(tempError);
    }
  };
  const uploadFile = async (e, index) => {
    setIsLoading(true);
    let data = new FormData();
    data.append("asset", e.target.files[0]);
    let image = await getImageUrl(data);

    if (errors?.length) {
      let tempError = errors?.slice(0);
      tempError[index].avatar = "";
      setErrors(tempError);
    }

    let temp = form.slice(0);
    temp[index].avatar = image;
    setForm(temp);

    setIsLoading(false);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      setErrors({});
      let temp = [...form];
      await createAflPlayerValidation.validate(
        { form: temp },
        {
          abortEarly: false,
        }
      );
      postApiReq(`/admin/add-afl-player`, {
        playerList: [...form],
        sportType,
      })
        .then((res) => {
          if (res.status) {
            setForm(initialState);
            dispatch(
              showToast({
                message: "Players created successfully",
                type: "success",
              })
            );
            if (isModal) handleClose();
            else {
              navigate("/afl-player");
            }

            setIsLoading(false);
          } else {
            dispatch(showToast({ message: res.error, type: "error" }));
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      if (isYupError(error)) {
        let _temp = parseYupError(error);
        setErrors(_temp?.form);
      } else {
      }
      setIsLoading(false);
    }
  };

  const addMorePlayer = () => {
    setForm([
      ...form,
      {
        playerName: "",
        avatar: "",
        type: "",
      },
    ]);
  };
  const removePlayer = (index) => {
    let temp = form.slice(0);
    temp.splice(index, 1);
    setForm(temp);
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>
          Avatar Fantasy League Player
        </Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid item xs={12} lg={3} xl={3} mb={10}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sport Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="type"
                    label="Sport Type"
                    value={sportType}
                    input={<OutlinedInput label="Sport Type" />}
                    onChange={(e) => setSportType(e.target.value)}
                  >
                    {Object.keys(_sportType).map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {_sportType[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {form.map((item, index) => (
                <Grid container spacing={3}>
                  <Grid
                    className={styles.imagesContainer}
                    item
                    xs={12}
                    lg={4}
                    xl={4}
                    mt={2}
                  >
                    <Button
                      className={styles.uploadButton}
                      variant="outlined"
                      component="label"
                    >
                      Upload Profile image
                      <input
                        type="file"
                        hidden
                        onChange={(e) => uploadFile(e, index)}
                      />
                    </Button>
                    <Grid item xs={12} lg={4} xl={4}>
                      {item.avatar && (
                        <img
                          className={styles.image}
                          src={item.avatar}
                          alt=""
                        />
                      )}
                      {errors.avatar && (
                        <small className="errormsg">
                          {errors[index].avatar}
                        </small>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={4} xl={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      error={errors[index]?.playerName}
                      helperText={errors[index]?.playerName}
                      type="text"
                      label="Name"
                      name="playerName"
                      value={item.playerName}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4} xl={4} mt={2}>
                    <FormControl fullWidth error={errors[index]?.type}>
                      <InputLabel id="demo-simple-select-label">
                        Player Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="type"
                        label="Player Category"
                        value={item.type}
                        input={<OutlinedInput label="player Category" />}
                        onChange={(e) => handleChange(e, index)}
                      >
                        {Object.keys(playerType[sportType]).map(
                          (key, index) => (
                            <MenuItem key={index} value={key}>
                              {playerType[sportType][key]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  {index > 0 && (
                    <Typography
                      onClick={() => removePlayer(index)}
                      className={styles.removeButton}
                    >
                      Remove player
                    </Typography>
                  )}
                </Grid>
              ))}
              <Grid item xs={11} align="left">
                <Typography
                  className={styles.addButton}
                  onClick={addMorePlayer}
                >
                  Add Player
                </Typography>
              </Grid>

              <Grid item xs={11} align="right">
                <Button size="large" onClick={handleSubmit} variant="contained">
                  Submit
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
