import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
  Box, Card, CardContent,
  TextField, InputAdornment, SvgIcon, Button,
  Grid
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '@components/Loader';
import { useNavigate } from 'react-router-dom';
import { getApiReq } from 'src/utils/ApiHandlers';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { imageHandler } from 'src/utils/formatter';
import AlertDialog from '@components/AlertDialog';
import { cutString } from 'src/utils/BasicRequest';

const playerType = {
  ALL_ROUNDER: "Allrounder",
  BATSMEN: "Batsman",
  BOWLER: "bowler",
  WICKET_KEEPER: "Wicketkeeper",
  GOAL_KEEPER: "Goalkeeper",
  DEFENDER: "Defender",
  MID_FIELDER: "Midfielder",
  FORWARD: "Forward",
}
const _sportType = {
  CRICKET: "Fantasy Cricket",
  SOCCER: "Fantasy Soccer",
};

export default function AflPlayer() {
  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const dispatch = useDispatch();
  const [playerList, setPlayerList] = useState([])

  const getPlayerList = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-afl-players?search=${search}`)
      .then(res => {
        if (res) {
          setPlayerList(res.data)
          setIsLoading(false);
        }
        else {
          dispatch(showToast({ message: res.error, type: 'error' }))
          setIsLoading(false);
        }
      }).catch(e => { console.log(e) })
  };

  const deletePlayer = () => {
    if (deleteId) {
      setShowAlert(false);
      setIsLoading(true);
      getApiReq(`/admin/delete-afl-player/${deleteId}`)
        .then(res => {
          if (res) {
            setPlayerList(playerList.filter(item => item._id !== deleteId))
            setIsLoading(false);                        
          }
          else {
            dispatch(showToast({ message: res.error, type: 'error' }))
            setIsLoading(false);                      
          }
        }).catch(e => { console.log(e) })
    }
    else return
  };
  useEffect(() => {
    getPlayerList();
  }, [search]);

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>Avatar Fantasy League Player</Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3} alignItems='center'>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} align="right">
                  <Button onClick={() => navigate('/add-afl-player')} size="large" variant="contained" startIcon={<AddCircleIcon />}>Add Player</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {/* <InfiniteScroll
          dataLength={length}
          next={getMoreList}
          hasMore={true}
          style={{ overflow: 'none' }}
        > */}

        <Table className='table' sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tabletitle}> S No.</TableCell>
              <TableCell className={styles.tabletitle} align="center">Avatar</TableCell>
              <TableCell className={styles.tabletitle} align="center">Player Name</TableCell>
              <TableCell className={styles.tabletitle} align="center">Sport</TableCell>
              <TableCell className={styles.tabletitle} align="center">Player Type</TableCell>
              <TableCell className={styles.tabletitle} align="center">Action</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>

            {playerList?.map((item, index) => (
              <TableRow
                className={styles.tableRow}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell  >{index + 1}</TableCell>
                <TableCell className={styles.dataView} align="center" ><img className={styles.roundImg} src={imageHandler(item.avatar, 4)} alt="" /></TableCell>
                <TableCell className={styles.dataView} align="center" >{cutString(item.playerName,20)}</TableCell>
                <TableCell className={styles.dataView} align="center" >{_sportType[item.sportType]}</TableCell>
                <TableCell className={styles.dataView} align="center" >{playerType[item.type]}</TableCell>

                <TableCell className={styles.action} align="center" onClick={() => { setShowAlert(true); setDeleteId(item._id) }}>Delete</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {playerList?.length < 1 && <Box align={'center'} w='100%' p={5} >
          <Typography variant="body1" textAlign="center" color="textSecondary">Data not Available</Typography>
        </Box>}

        {/* </InfiniteScroll> */}
        <Loader isLoading={isLoading} />
      </TableContainer >
      <AlertDialog showAlert={showAlert} onClose={() => { setShowAlert(false); setDeleteId(null) }} handleSubmit={deletePlayer} />
    </React.Fragment >
  );
}
