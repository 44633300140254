import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  TextareaAutosize,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import Loader from "@components/Loader";

import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { useNavigate } from "react-router-dom";
import { getImageUrl, postApiReq } from "src/utils/ApiHandlers";
import { createAvatarPackValidation } from "@utils/validation";
import { isYupError, parseYupError } from "@utils/Yup";

const initialState = {
  packName: "",
  asset: "",
  supply: 1,
  description: "",
  cardInside: 1,
  chain: "POLYGON",
  cardType:'',
  price: 0.1,
};

const supplyRegx = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export default function CreateAflPack() {
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const uploadFile = async (e) => {
    let data = new FormData();
    data.append("asset", e.target.files[0]);
    let image = await getImageUrl(data);
    setForm({
      ...form,
      [e.target.name]: image,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };



  const handleSubmit = async () => {
    let data = {
      ...form,
      cardInside: Number(form.cardInside),
      price: Number(form.price),
      supply: Number(form.supply) * Number(form.cardInside),
    };
    setIsLoading(true);
    try {
      setErrors({});
      await createAvatarPackValidation.validate(data, {
        abortEarly: false,
      });
      postApiReq(`/admin/avatar-pack`, data)
        .then((res) => {
          if (res.status) {
            dispatch(
              showToast({
                message: "Avatar pack created successfully",
                type: "success",
              })
            );
            navigate("/afl-packs");
            setIsLoading(false);
          } else {
            dispatch(showToast({ message: res.error, type: "error" }));
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
      }
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleSupplyChange = (e) => {
    if (supplyRegx.test(e.target.value) || e.target.value === "") {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
      setErrors({
        ...errors,
        [e.target.name]: "",
      });
    }
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>
          {"Avatar Fantasy League Pack"}
        </Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box ml={10}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={5}>
                    <TextField
                      error={errors.packName}
                      margin="normal"
                      fullWidth
                      type="text"
                      label="Pack name"
                      name="packName"
                      value={form.packName}
                      helperText={errors.packName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <TextField
                      margin="normal"
                      fullWidth
                      error={errors.supply}
                      type="text"
                      label="Collection Pack Supply"
                      name="supply"
                      helperText={errors.supply}
                      value={form.supply}
                      onChange={handleSupplyChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <TextField
                      error={errors.cardInside}
                      margin="normal"
                      fullWidth
                      type="text"
                      label="Number of Cards Inside Pack"
                      name="cardInside"
                      helperText={errors.cardInside}
                      value={form.cardInside}
                      onChange={handleSupplyChange}
                    />
                  </Grid>

                  <Grid mt={2} item xs={12} lg={5} >
                    <FormControl fullWidth error={Boolean(errors.chain)}>
                      <InputLabel id="demo-simple-select-label">
                        Select Chain
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={form.chain}
                        label="Select Chain"
                        name="chain"
                        onChange={handleChange}
                      >
                        <MenuItem value="POLYGON">POLYGON</MenuItem>
                      </Select>
                      <FormHelperText>{errors.chain}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <TextField
                      error={errors.price}
                      margin="normal"
                      fullWidth
                      helperText={errors.chain}
                      type="text"
                      label="Price"
                      name="price"
                      value={form.price}
                      onChange={handleSupplyChange}
                    />
                  </Grid>
                  <Grid item mt={2} xs={12} lg={5}>
                    <FormControl fullWidth error={errors.sportId}>
                      <InputLabel id="demo-simple-select-label">
                        Card Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="cardType"
                        label="Card Type"
                        value={form.cardType}
                        input={<OutlinedInput label="Card Type" />}
                        onChange={handleChange}
                      >
                        <MenuItem value={"BLUE"}>Blue</MenuItem>
                        <MenuItem value={"SILVER"}>Silver</MenuItem>

                        <MenuItem value={"GOLD"}>Gold</MenuItem>

                        <MenuItem value={"BLACK"}>Black</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid className={styles.imagesContainer} item xs={12} lg={5}>
                    <Button
                      className={styles.uploadButton}
                      variant="outlined"
                      component="label"
                    >
                      Upload Pack Image
                      <input
                        type="file"
                        name="asset"
                        onChange={uploadFile}
                        hidden
                      />
                    </Button>
                    {form.asset && (
                      <img
                        className={styles.image}
                        src={form.asset}
                        alt="asset"
                      />
                    )}
                    {errors.asset && (
                      <small className="errormsg">{errors.asset}</small>
                    )}
                  </Grid>
                  <Grid item xs={10} lg={10}>
                    <TextareaAutosize
                      aria-label="description"
                      minRows={7}
                      placeholder="Description"
                      name="description"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    {errors.description && (
                      <small className="errormsg">{errors.description}</small>
                    )}
                  </Grid>

                  <Grid item xs={11} align="right">
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
