
import ENV from './env';
export const imageHandler = (path, type) => {

  switch (type) {
    case 1:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/team/logo/${path}`;
      }
      else {
        return path;
      }

    case 2:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/team/cover/${path}`;
      }
      else {
        return path;
      }
    case 3:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/collection/collectionPack/${path}`;
      }
      else {
        return path;
      }
    case 4:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/player/profile/${path}`;
      }
      else {
        return path;
      }
    case 5:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/collection/fanCard/${path}`;
      }
      else {
        return path;
      }
    case 6:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/heros/profile/${path}`;
      }
      else {
        return path;
      }
    case 7:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/user/profile/${path}`;
      }
      else {
        return path;
      }
      case 8:
        if (path && path.indexOf('http') === -1) {
          return `${ENV.BASE_URL}/user/banner/${path}`;
        }
        else {
          return path;
        }
        case 9:
          if (path && path.indexOf('http') === -1) {
            return `${ENV.BASE_URL}/collection/logo/${path}`;
          }
          else {
            return path;
          }
          case 10:
            if (path && path.indexOf('http') === -1) {
              return `${ENV.BASE_URL}/player/avatar/${path}`;
            }
            else {
              return path;
            }
  
    default:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/${path}`;
      }
      else {
        return path;
      }

  }

}