const ENV = {
  BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:9001"
      : "https://api.cardpooler.com",
}


export const contracts = {
  POLYGON: {
    WyvernProxyRegistry: "0x6040EE5F8702134d692aB1De6dC2C0d7e5741191",
    WyvernExchange: "0x6348628E3f7B9d198c26AEAb208bEB79daCb7EE7",
    WyvernTokenTransferProxy: "0x0A2E3D80619165ad741eDC998292CEB3E974896f",
    AssetContractShared: "0x8e0d1BCB0e6aE27f09fd105a6F2726963a58F4CC",
    MarketV2: "0x40D86C4298c987fdb0c4B49F980766fa20e4a53F",
    Lootbox: "0x992f749a9a75f52991753f9f79b9f508d689962e",
  },
};

export const feeRecipient = "0xd2645b8E1DE56d53236bd0EB2728Efa9Ae710003";
export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const noAddress = null;

/**
 * Deployed exchange contract on network
 */
export const chainId = {
  ETHEREUM: "0x3",
  POLYGON: "0x13882",
};

export const tokenAddress = {
  ETHEREUM: {
    ETH: zeroAddress,
    WETH: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
  },
  POLYGON: {
    MATIC: zeroAddress,
    WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
  },
};

// export const noAddress = null

export default ENV;