import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import { Select, MenuItem } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "@components/Loader";
import { useNavigate } from "react-router-dom";
import { getApiReq, postApiReq } from "src/utils/ApiHandlers";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { imageHandler } from "src/utils/formatter";
import AlertDialog from "@components/AlertDialog";
import moment from "moment";
import PlayerList from "./playerList";

const _sportType = {
  CRICKET: "Fantasy Cricket",
  SOCCER: "Fantasy Soccer",
};


export default function AflMatch() {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [matchList, setMatchList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [matchId, setMatchId] = useState({ id: '', index: 0 })


  const getMatchList = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-afl-match?search=${search}`)
      .then((res) => {
        if (res) {
          setMatchList(res.data);
          setIsLoading(false);
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateMatchStatus = (id, status, index, data) => {

    let { players } = data.secondTeamInfo;
    let { players: playerTwo } = data.firstTeamInfo;
    let allPlayer = [...players, ...playerTwo];
    console.log(allPlayer)


    setIsLoading(true);
    postApiReq(`/admin/update-afl-match-status/${id}`, { status, playerList: allPlayer })
      .then((res) => {
        if (res.status) {
          let temp = [...matchList];
          temp[index].status = status;
          setMatchList(temp);
          setIsLoading(false);
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteMatch = () => {
    if (deleteId) {
      setShowAlert(false);
      setIsLoading(true);
      getApiReq(`/admin/delete-afl-match/${deleteId}`)
        .then((res) => {
          if (res) {
            setMatchList(matchList.filter((item) => item._id !== deleteId));
            setIsLoading(false);
          } else {
            dispatch(showToast({ message: res.error, type: "error" }));
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else return;
  };
  useEffect(() => {
    getMatchList();
  }, [search]);

  const handleOpenDialog = (data, index) => {
    let { players } = data.secondTeamInfo;
    let { players: playerTwo } = data.firstTeamInfo;
    let allPlayer = [...players, ...playerTwo];
    console.log(allPlayer);
    setIsLoading(true);
    postApiReq(`/admin/get-afl-players-by-ids`, { ids: allPlayer })
      .then((res) => {
        if (res.status) {
          setUserList(res.data);
          setIsLoading(false);
          setDialogOpen(true)
          setMatchId({ id: data._id, index: index })
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmit = () => {

    postApiReq(`/admin/update-afl-player-points/${matchId.id}`, { playerList: userList })
      .then((res) => {
        if (res.status) {
          dispatch(
            showToast({
              message: "points updated successfully",
              type: "success",
            })
          );
          updateMatchStatus(matchId.id, 'SETTLED', matchId.index)
          handleCloseDialog();
          setIsLoading(false);
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>Avatar Fantasy League Match</Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} align="right">
                  <Button
                    onClick={() => navigate("/add-afl-match")}
                    size="large"
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                  >
                    Add Match
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {/* <InfiniteScroll
          dataLength={length}
          next={getMoreList}
          hasMore={true}
          style={{ overflow: 'none' }}
        > */}

        <Table
          className="table"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles.tabletitle}>S No.</TableCell>
              <TableCell className={styles.tabletitle} align="center">
                Image
              </TableCell>
              <TableCell className={styles.tabletitle} align="center">
                Match Name
              </TableCell>
              <TableCell className={styles.tabletitle} align="center">
                Teams
              </TableCell>

              <TableCell className={styles.tabletitle} align="center">
                Start Time
              </TableCell>
              <TableCell className={styles.tabletitle} align="center">
                sport
              </TableCell>
              <TableCell className={styles.tabletitle} align="center">
                Status
              </TableCell>

              <TableCell className={styles.tabletitle} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchList?.map((item, index) => (
              <TableRow
                className={styles.tableRow}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell className={styles.dataView} align="center">
                  {" "}
                  <div className="image-container">
                    <img
                      className={styles.roundImg}
                      src={imageHandler(item?.firstTeamInfo?.logo, 1)}
                      alt=""
                    />
                    <img
                      className={styles.roundImg}
                      src={imageHandler(item?.secondTeamInfo?.logo, 1)}
                      alt=""
                    />
                  </div>
                </TableCell>
                <TableCell className={styles.dataView} align="center">
                  {item.matchName}
                </TableCell>
                <TableCell className={styles.dataView} align="center">
                  {item?.firstTeamInfo?.abbreviation || ""} /{" "}
                  {item?.secondTeamInfo?.abbreviation || ""}
                </TableCell>

                <TableCell className={styles.dataView} align="center">
                  {moment(item.startTime).format("DD MMM YYYY [At] hh:mm A")}
                </TableCell>
                <TableCell className={styles.dataView} align="center">
                  {_sportType[item.sportType]}
                </TableCell>
                <TableCell className={styles.tabletitle} align="center">
                  <Select
                    labelId="demo-simple-select-label"
                    name="cardType"
                    label="Card Type"
                    value={item.status}
                    input={<OutlinedInput label="Card Type" />}
                    onChange={(e) =>
                      updateMatchStatus(item._id, e.target.value, index, item)
                    }
                  >
                    <MenuItem disabled={item.status === 'IN_PLAY' || item.status === 'FINISH'} value={"ACTIVE"}>Active</MenuItem>
                    <MenuItem disabled={item.status === 'FINISH'} value={"IN_PLAY"}>In Play</MenuItem>

                    <MenuItem disabled={item.status !== 'IN_PLAY'} value={"FINISH"}>Finish</MenuItem>
                  </Select>
                </TableCell>

                <TableCell
                  className={styles.action}
                  align="center"
                  onClick={() => {
                    setShowAlert(true);
                    setDeleteId(item._id);
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {matchList?.length < 1 && (
          <Box align={"center"} w="100%" p={5}>
            <Typography
              variant="body1"
              textAlign="center"
              color="textSecondary"
            >
              Data not Available
            </Typography>
          </Box>
        )}

        {/* </InfiniteScroll> */}
        <Loader isLoading={isLoading} />
      </TableContainer>
      <AlertDialog
        showAlert={showAlert}
        onClose={() => {
          setShowAlert(false);
          setDeleteId(null);
        }}
        handleSubmit={deleteMatch}
      />
      <PlayerList
        players={userList}
        setUserList={setUserList}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
      />
    </React.Fragment>
  );
}
