import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  Typography
} from "@mui/material";

const PlayerList = ({ players, setUserList,open, onClose, onSubmit }) => {


  const handleChange = (event, index) => {
    let _players=[...players]
    _players[index].playerPoint=Number(event.target.value)
    setUserList(_players)
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Player Points</DialogTitle>
      <DialogContent>
        <List>
          {players.map((player,index) => (
            <ListItem key={players._id} style={{display:'flex',justifyContent:'space-between'}}>
              <Typography  style={{ flex: 1 }} fullWidth>{player.playerName}</Typography>

              <TextField
              style={{ flex: 1 }}
                label={`Points for ${player.playerName}`}
                value={player.playerPoint||0}
                onChange={(event) => handleChange(event, index)}
                fullWidth
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerList;
